import { rpc, sc, tx, u, wallet } from '@cityofzion/neon-js';
import { readContract } from '@wagmi/core';
import { erc20Abi } from 'viem';
import { neoxBridgeAbi } from './abis/neox-bridge';
import { post } from './tools';
import { walletConfig } from './walletConfig';

const N3_GAS_CONTRACT = process.env.REACT_APP_GAS_CONTRACT as string;
const BRIDGE_CONTRACT = process.env.REACT_APP_NEO_BRIDGE_CONTRACT as string;
const NEOX_CONTRACT = process.env.REACT_APP_NEOX_BRIDGE_CONTRACT as `0x${string}`;
const NEO_RPC = process.env.REACT_APP_NEO_RPC as string;

export const getNetworkFee = async (
  from: any,
  to: any,
  amount: any,
  maxFee: any,
  fromScriptHash: string,
  publicKey: string,
): Promise<string> => {
  try {
    const invocation = {
      scriptHash: BRIDGE_CONTRACT,
      operation: 'depositNative',
      args: [
        sc.ContractParam.fromJson(from),
        sc.ContractParam.fromJson(to),
        sc.ContractParam.fromJson(amount),
        sc.ContractParam.fromJson(maxFee),
      ],
    };
    const txSigners = [
      {
        account: fromScriptHash,
        scopes: 16,
        allowedContracts: [BRIDGE_CONTRACT, N3_GAS_CONTRACT],
        allowedGroups: [],
      },
    ];
    const script = sc.createScript(invocation);
    const rpcClient = new rpc.RPCClient(NEO_RPC);
    const currentHeight = await rpcClient.getBlockCount();
    const transaction = new tx.Transaction({
      signers: txSigners,
      validUntilBlock: currentHeight + 5000,
      script: script,
      witnesses: [
        {
          invocationScript: '',
          verificationScript: wallet.getVerificationScriptFromPublicKey(publicKey),
        },
      ],
    });
    const fetchInitData = {
      method: 'POST',
      body: JSON.stringify({
        params: [u.HexString.fromHex(transaction.serialize(true)).toBase64()],
        method: 'calculatenetworkfee',
        jsonrpc: '2.0',
        id: 1,
      }),
    };
    const response = await fetch(NEO_RPC, fetchInitData);
    const detail = await response?.json();
    const networkfee = detail?.result?.networkfee;
    return networkfee;
  } catch (err) {
    console.log(err);
    return '0';
  }
};

export const getN3RegisteredTokens = (): Promise<any> => {
  const body = JSON.stringify({
    params: [BRIDGE_CONTRACT, 'getRegisteredTokens'],
    method: 'invokefunction',
    jsonrpc: '2.0',
    id: 1,
  });
  return post(NEO_RPC, body).then(res => {
    return getN3TokensInfo(res.result.stack[0].value);
  });
};

export const getN3TokensInfo = async (contracts: any[]): Promise<any> => {
  const promiseArr: any = [];
  contracts.forEach((v: any) => {
    const contract = `0x${u.reverseHex(u.base642hex(v.value))}`;
    promiseArr.push(
      post(
        NEO_RPC,
        JSON.stringify({
          params: [contract, 'symbol'],
          method: 'invokefunction',
          jsonrpc: '2.0',
          id: 1,
        }),
      ),
      post(
        NEO_RPC,
        JSON.stringify({
          params: [contract, 'decimals'],
          method: 'invokefunction',
          jsonrpc: '2.0',
          id: 1,
        }),
      ),
    );
  });
  const res = await Promise.all(promiseArr);
  const tokenMap: any = {};
  for (let i = 0; i < res.length - 1; i += 2) {
    if (res[i].result.state === 'HALT' && res[i].result.stack?.[0]?.value) {
      tokenMap[u.base642utf8(res[i].result.stack[0].value).toUpperCase()] = {
        n3Decimals: Number(res[i + 1].result.stack[0].value),
        n3Contract: `0x${u.reverseHex(u.base642hex(contracts[i / 2].value))}`,
      };
    }
  }
  return tokenMap;
};

export const getNeoxRegisteredTokens = (index: number, arr: string[]): Promise<string[]> => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return readContract(walletConfig, {
    abi: neoxBridgeAbi,
    address: NEOX_CONTRACT,
    functionName: 'registeredTokens',
    args: [index],
  })
    .then((res: any) => {
      arr.push(res as string);
      return getNeoxRegisteredTokens(index + 1, arr);
    })
    .catch(() => {
      if (arr.length > 0) {
        return getNeoxTokensInfo(arr as any);
      }
      return arr;
    });
};

export const getNeoxTokensInfo = async (contracts: `0x${string}`[]): Promise<any> => {
  const promiseArr: any = [];
  contracts.forEach(contract => {
    promiseArr.push(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      readContract(walletConfig, {
        abi: erc20Abi,
        address: contract,
        functionName: 'symbol',
      }),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      readContract(walletConfig, {
        abi: erc20Abi,
        address: contract,
        functionName: 'decimals',
      }),
    );
  });
  const res = await Promise.all(promiseArr);
  const tokenMap: any = {};
  for (let i = 0; i < res.length - 1; i += 2) {
    tokenMap[res[i].toUpperCase()] = {
      neoxDecimals: Number(res[i + 1]),
      neoxContract: contracts[i / 2],
    };
  }
  return tokenMap;
};

export const getDepositIsPaused = (hash?: string): Promise<boolean> => {
  const body1 = JSON.stringify({
    params: [BRIDGE_CONTRACT, 'isPaused'],
    method: 'invokefunction',
    jsonrpc: '2.0',
    id: 1,
  });
  const body2 = JSON.stringify({
    params: [BRIDGE_CONTRACT, 'depositsArePaused'],
    method: 'invokefunction',
    jsonrpc: '2.0',
    id: 1,
  });
  const body3 = JSON.stringify({
    params: [BRIDGE_CONTRACT, 'getNativeBridge'],
    method: 'invokefunction',
    jsonrpc: '2.0',
    id: 1,
  });
  const body4 = JSON.stringify({
    params: [
      BRIDGE_CONTRACT,
      'getTokenBridge',
      [
        {
          type: 'Hash160',
          value: hash,
        },
      ],
    ],
    method: 'invokefunction',
    jsonrpc: '2.0',
    id: 1,
  });
  const promiseArr = hash
    ? [post(NEO_RPC, body1), post(NEO_RPC, body2), post(NEO_RPC, body4)]
    : [post(NEO_RPC, body1), post(NEO_RPC, body2), post(NEO_RPC, body3)];
  return Promise.all(promiseArr).then(res => {
    if (
      res[0].result?.stack?.[0]?.value === true ||
      res[1].result?.stack?.[0]?.value === true ||
      res[2].result?.stack?.[0]?.value?.[0]?.value !== '0'
    ) {
      return true;
    } else {
      return false;
    }
  });
};

export const getWithdrawIsPaused = (hash?: string): Promise<boolean> => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const promise1 = readContract(walletConfig, {
    abi: neoxBridgeAbi,
    address: NEOX_CONTRACT,
    functionName: 'bridgePaused',
  });
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const promise2 = readContract(walletConfig, {
    abi: neoxBridgeAbi,
    address: NEOX_CONTRACT,
    functionName: 'withdrawalsPaused',
  });
  const promiseArr = hash
    ? [
        promise1,
        promise2,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        readContract(walletConfig, {
          abi: neoxBridgeAbi,
          address: NEOX_CONTRACT,
          functionName: 'tokenBridges',
          args: [hash],
        }),
      ]
    : [
        promise1,
        promise2,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        readContract(walletConfig, {
          abi: neoxBridgeAbi,
          address: NEOX_CONTRACT,
          functionName: 'nativeBridge',
        }),
      ];
  return Promise.all(promiseArr).then((res: any) => {
    if (res[0] === true || res[1] === true || res[2][0] === true) {
      return true;
    } else {
      return false;
    }
  });
};
